.banner-search-section
{
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: 540px;

    overflow: hidden;

    background-image: url("/public/image/wallpaper.jpg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    transition-duration: 0.5s;
    transition-timing-function: ease;
}

@media (max-width: 575.98px)
{
    .banner-search-section
    {
        background-image: url("/public/image/wallpaper_mobile.jpg");
        background-repeat: no-repeat;
        background-position: right top 45%;
        background-size: cover;
    }
}

.banner-search-result-section
{
    padding-top: 78px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.banner-search-overlay
{
    position: absolute;
    width: 100%;
}

.banner-search-title-mobile
{
    margin: 0;
    margin-bottom: 18px;
    padding-top: 0px;

    text-align: center;
    font-size: 44px;
    line-height:44px;

    color: #ffffff;

    transition-duration: 0.5s;
    transition-timing-function: ease;
}

.banner-search-title-desktop
{
    margin: 0;
    margin-bottom: 0px;
    padding-top: 0px;

    text-align: center;
    font-size: 64px;
    line-height: 72px;

    color: #ffffff;

    transition-duration: 0.5s;
    transition-timing-function: ease;
}

.banner-search-subtitle-mobile
{
    margin-top: 0px;
    margin-bottom: 29px;
    margin-left: 32px;
    margin-right: 32px;
    
    text-align: center;
    font-size: 24px;
    line-height: 28px;

    color: #ffffff;

    visibility: visible;
    opacity: 1;

    transition-duration: 0.5s;
    transition-timing-function: ease;
}

.banner-search-subtitle-desktop
{
    margin-top: 0px;
    margin-bottom: 29px;
    
    text-align: center;
    font-size: 26px;
    line-height: 28px;

    color: #ffffff;

    transition-duration: 0.5s;
    transition-timing-function: ease;
}

.banner-search-search-container-mobile
{
    width: 340px;
    height: 56px;
    margin: 0 auto;

    background: rgb(188,235,96);
    background: linear-gradient(90deg, rgba(188,235,96,1) 0%, rgba(114,190,32,1) 100%);

    border-radius: 28px;
    border: none;
}

.banner-search-search-container-desktop
{
    width: 500px;
    height: 56px;
    margin: 0 auto;

    background: rgb(188,235,96);
    background: linear-gradient(90deg, rgba(188,235,96,1) 0%, rgba(114,190,32,1) 100%);

    border-radius: 28px;
    border: none;
}

.banner-search-search-input-mobile
{
position: relative;

    top: 3px;
    left: 3px;

    width: 280px;
    height: 50px;

    padding: 0;
    padding-left: 25px;
 
    border-radius: 25px;
    border: none;
}

.banner-search-search-input-desktop
{
    position: relative;

    top: 3px;
    left: 3px;

    width: 440px;
    height: 50px;

    padding: 0;
    padding-left: 25px;

    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border: none;
}

.banner-search-search-button
{
    position: relative;

    left: 6px;
    top: 2px;

    width: 48px;
    height: 50px;

    color: #ffffff;
    background-color: transparent;

    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    border: none;
}

.banner-search-search-button:hover
{
    cursor: pointer;

    transition: all .25s ease-out;
}

.banner-search-checks
{
    margin-top: 20px;

    text-align: center;

    visibility: visible;
    opacity: 1;

    transition-duration: 0.5s;
    transition-timing-function: ease;
}

.banner-search-check-mobile
{
    margin-bottom: 0px;

    font-size: 24px;
    line-height: 29px;

    color: #ffffff;
}

.banner-search-check-desktop
{
    margin-bottom: 0px;

    font-size: 26px;
    line-height: 30px;

    color: #ffffff;
}

.banner-search-check-checkmark
{
    padding-right: 5px;

    color: #aedf54;
}

.banner-search-text
{
    margin-top: 30px;

    text-align: center;
}

.banner-category-section
{
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    height: 260px;

    overflow: hidden;
    
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}

.banner-category-overlay
{
    position: absolute;
    width: 100%;
}

.banner-category-title
{
    margin: 0;
    margin-bottom: 0px;
    padding-top: 15px;

    text-align: center;
    font-size: 48px;
    line-height: 58px;

    color: #ffffff;
}

.banner-category-subtitle
{
    margin-top: 0px;
    margin-bottom: 29px;
    
    text-align: center;
    font-size: 19px;
    line-height: 22px;

    color: #ffffff;
}

.banner-title-section
{
    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    padding-top: 48px;
    padding-bottom: 48px;
}

.banner-title-overlay
{
    position: absolute;
    width: 100%;
}

.banner-title-mobile
{
    margin: 0;
    margin-bottom: 0px;

    text-align: center;
    font-size: 32px;

    color: #000000;
}

.banner-title-tablet
{
    margin: 0;
    margin-bottom: 0px;

    text-align: center;
    font-size: 48px;

    color: #000000;
}

.banner-title-desktop
{
    margin: 0;
    margin-bottom: 0px;

    text-align: center;
    font-size: 64px;

    color: #000000;
}

.banner-subtitle-mobile
{
    margin-top: 0px;
    margin-bottom: 29px;
    
    text-align: center;
    font-size: 16px;
    line-height: 19px;

    color: #000000
}

.banner-subtitle-desktop
{
    margin-top: 0px;
    margin-bottom: 29px;
    
    text-align: center;
    font-size: 24px;
    line-height: 28px;

    color: #000000
}

.banner-notification-mobile
{
    position: relative;

    top: -16px;
    right: 0px;

    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-left: 6px;
    border-radius: 1.0em;

    color: #ffffff;
    background-color: #fd0603;

    font-size: 16px;
}

.banner-notification-tablet
{
    position: relative;

    top: -24px;
    right: 0px;

    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-left: 6px;
    border-radius: 1.0em;

    color: #ffffff;
    background-color: #fd0603;

    font-size: 16px;
}

.banner-notification-desktop
{
    position: relative;

    top: -32px;
    right: 0px;

    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-left: 6px;
    border-radius: 1.0em;

    color: #ffffff;
    background-color: #fd0603;

    font-size: 24px;
}

.banner-contact-section
{
    padding-top: 64px;
    padding-bottom: 32px;
    margin-top: 0px;

    min-height: 700px;
}

@media (max-width: 767.98px)
{
    .banner-contact-section
    {
        padding-top: 32px;
    }
}

.banner-contact-column-left
{
    padding: 30px;

    padding-left: 30px;
    padding-right: 30px;

    margin-left: 0px;
    margin-right: 20px;
    margin-bottom: 60px;

    background-color: #ffffff;

    border-style: solid;
    border-radius: 24px;
    border-width: 1px;
    border-color: #eeeeee;

    box-shadow: 0px 10px 32px #00000017;
}

.banner-contact-column-text-domainform
{
    margin-bottom: 8px;

    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.banner-contact-column-text-contactform
{
    display: block;

    margin-bottom: 24px;

    font-size: 16px;
    line-height: 19px;

    color: #000000;
}

.banner-contact-column-title-xs
{
    margin-top: 10px;
    margin-bottom: 12px;

    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    line-break: anywhere;

    color: #000000;
}

.banner-contact-column-title-sm
{
    margin-top: 10px;
    margin-bottom: 12px;

    font-size: 29px;
    font-weight: 700;
    line-height: 36px;
    line-break: anywhere;

    color: #000000;
}

.banner-contact-column-title-md
{
    margin-top: 10px;
    margin-bottom: 12px;

    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    line-break: anywhere;

    color: #000000;
}

.banner-contact-column-title-lg
{
    margin-top: 10px;
    margin-bottom: 12px;

    font-size: 23px;
    font-weight: 700;
    line-height: 27px;
    line-break: anywhere;

    color: #000000;
}

.banner-contact-column-title-xl
{
    margin-top: 10px;
    margin-bottom: 12px;

    font-size: 29px;
    font-weight: 700;
    line-height: 36px;
    line-break: anywhere;

    color: #000000;
}

.banner-contact-column-title-xxl
{
    margin-top: 10px;
    margin-bottom: 12px;

    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
    line-break: anywhere;

    color: #000000;
}

.banner-contact-column-new
{
    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-right: 6px;
    border-radius: 1.0em;

    font-size: 16px;
    font-weight: 500;

    color: #878787;
    border: 1px solid #878787;
}

.banner-contact-column-discount
{
    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-right: 6px;
    border-radius: 1.0em;

    font-size: 16px;
    font-weight: 500;

    color: #ffffff;
    background-color: #a6dc4f;
}

.banner-contact-column-premium
{
    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-right: 6px;
    border-radius: 1.0em;

    font-size: 16px;
    font-weight: 500;

    color: #ffffff;
    background-color: #514fdc;
}

.banner-contact-column-premium-star
{
    color: #ffd21d; 
}

.banner-contact-column-offered
{
    margin-top: 24px;
    margin-bottom: 24px;
}

.banner-contact-column-checkmarks
{
    margin-top: 24px;
    margin-bottom: 24px;
}

.banner-contact-column-checkmark
{
    padding-right: 5px;

    color: #aedf54;
}

.banner-contact-column-thankyou
{
    padding-top: 24px;
}

.banner-contact-container-mobile
{
    padding: 30px;

    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: -30px;
    margin-right: -30px;

    background: rgb(188,235,96);
    background: linear-gradient(90deg, rgba(188,235,96,1) 0%, rgba(114,190,32,1) 100%);
}

.banner-contact-container-side
{
    position: relative;

    padding: 30px;
    padding-left: 30px;
    padding-right: 30px;

    margin-top: 64px;
    margin-left: -45px;
    margin-bottom: 60px;

    background: rgb(188,235,96);
    background: linear-gradient(90deg, rgba(188,235,96,1) 0%, rgba(114,190,32,1) 100%);

    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;

    box-shadow: 0px 10px 32px #00000017;
}

.banner-contact-profile-inner
{
    display: flex;
    align-items: center;
    justify-content: left;
}

.banner-contact-profile-picture
{
    flex: 0 0 140px;
    height: 140px;

    margin-right: 16px;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: 140px 140px;

    border-style: solid;
    border-radius: 100%;
    border-width: 2px;
    border-color: #ffffff;
}

.banner-contact-profile-intro
{
    display: block;
    
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    
    color: #ffffff;
}

.banner-contact-profile-name
{
    display: block;

    padding-top: 16px;

    font-size: 24px;
    font-weight: 500;
    line-height: 26px;

    color: #ffffff;
}

.banner-contact-profile-quote
{
    display: block;

    padding-top: 4px;

    font-size: 16px;
    line-height: 19px;

    color: #ffffff;
}

.banner-contact-profile-buttons
{
    display: block;

    padding-top: 16px;
}

.banner-contact-profile-linkedin
{
    display: inline-block;

    margin-top: 0px;
    margin-bottom: 12px;
    margin-right: 12px;

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    
    width: 140px;
    height: 32px;

    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;

    color: #ffffff;
    background-color: #0b66c2;

    border-radius: 6px;
}

.banner-contact-profile-linkedin:hover
{
    cursor: pointer;
    
    text-decoration: none;
    color: #ffffff;
    background-color: #1f7ad5;
}
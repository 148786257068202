.cta-section
{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 64px;
}

.cta-mobile
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    margin: 0 auto;
    margin-top: 12px;
    width: 300px;
    height: 48px;

    color: #ffffff;
    background: rgb(188,235,96);
    background: linear-gradient(90deg, rgba(188,235,96,1) 0%, rgba(114,190,32,1) 100%);

    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
}

.cta-mobile:hover
{
    cursor: pointer;

    color: #ffffff;
    transition: all .25s ease-out;
}

.cta-desktop
{
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    position: relative;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    margin: 0 auto;
    margin-top: 12px;
    width: 400px;
    height: 56px;

    color: #ffffff;
    background: rgb(188,235,96);
    background: linear-gradient(90deg, rgba(188,235,96,1) 0%, rgba(114,190,32,1) 100%);

    border-top-left-radius: 28px;
    border-bottom-left-radius: 28px;
    border-top-right-radius: 28px;
    border-bottom-right-radius: 28px;
}

.cta-desktop:hover
{
    cursor: pointer;

    color: #ffffff;
    transition: all .25s ease-out;
}

.cta-text-mobile
{
    width: 100%;

    color: #ffffff;
    font-size: 24px;
    text-align: center;
}

.cta-text-desktop
{
    width: 100%;

    color: #ffffff;
    font-size: 24px;
    text-align: center;
}

.cta-notification-mobile
{
    position: absolute;

    top: -16px;
    right: -16px;

    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-left: 6px;
    border-radius: 1.0em;

    color: #ffffff;
    background-color: #fd0603;

    font-size: 16px;
}

.cta-notification-desktop
{
    position: absolute;

    top: -16px;
    right: -16px;

    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-left: 6px;
    border-radius: 1.0em;

    color: #ffffff;
    background-color: #fd0603;

    font-size: 20px;
}
.navbar-section
{
    background: rgb(178,231,72);
    background: linear-gradient(90deg, rgba(178,231,72,1) 0%, rgba(99,175,16,1) 100%);
}

.navbar-container
{
    padding-top: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;

    min-height: 60px;
}

/* XS */
@media (max-width: 575.98px)
{
    .navbar-container
    {
        padding-left: 12px;
        padding-right: 12px;
    }
}

.navbar-justify-right
{
    justify-content: right;
}

.navbar-title
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    
    height: 100%;

    color: #ffffff;

    font-size: 22px;
    font-weight: 500;
    text-decoration: none;
}

.navbar-title:hover
{
    color: #ffffff;

    text-decoration: none !important;

    cursor: pointer;
}

/* Used by Bootstrap */
.navbar-toggler
{
    border-style: solid;
    border-width: 1px;
    border-color: #ffffff;
    border-radius: 8px;

    /* Fix for button displacement on dropdown open */
    height: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.navbar-listitem
{
    padding-top: 0px;
    padding-bottom: 0px;
}

.navbar-item
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    
    height: 100%;
    min-height: 46px;
    margin-left: 32px;
    margin-right: 0px;

    color: #ffffff;

    font-size: 16px;
    text-decoration: none;

    border-top: 3px solid #ffffff00;
    border-bottom: 3px solid #ffffff00;
    
    transition: all .25s ease-out;
}

@media (max-width: 991.98px)
{
    .navbar-item
    {
        margin-left: 0px;
    }
}

.navbar-item:hover
{
    color: #ffffff;

    border-bottom: 3px solid #ffffffff;

    text-decoration: none !important;
    cursor: pointer;

    transition: all .25s ease-out;
}

.navbar-item-notification
{
    position: relative;

    top: -8px;
    right: 0px;

    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-left: 3px;
    border-radius: 1.0em;

    color: #ffffff;
    background-color: #fd0603;

    font-size: 11px;
    font-weight: 500;
}

.navbar-spacing
{
    margin-top: 60px;
}

/* Full height */
.navbar-nav
{
    min-height: 60px;
}

.navbar-scroll
{
    max-height: calc(100vh - 160px);
    overflow-y: scroll;
}

/* XL XXL */
@media (min-width: 992px)
{
    .navbar-scroll
    {
        overflow: hidden;
    }
}

/* Override to behave like navbar-item */
.nav-link
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    
    height: 100%;
    margin-left: 32px;
    margin-right: 0px;
    padding-left: 0px !important;
    padding-right: 0px !important;

    color: #ffffffff !important; /* Opacity fix */

    font-size: 16px;
    text-decoration: none;

    border-top: 3px solid #ffffff00;
    border-bottom: 3px solid #ffffff00;
    
    transition: all .25s ease-out;
}

/* XS to MD */
@media (max-width: 991.98px)
{
    .nav-link
    {
        margin-left: 0px;
    }
}

.nav-link:hover
{
    border-bottom: 3px solid #ffffffff;

    text-decoration: none !important;
    cursor: pointer;

    transition: all .25s ease-out;
}

/* XS to MD */
@media (max-width: 991.98px)
{
    .nav-link:hover
    {
        border-bottom: 3px solid #ffffff00;
    }
}

.navbar-nav .nav-link.active, .navbar-nav .show>.nav-link
{
    color: rgba(255, 255, 255, 0.750) !important;
}

.dropdown
{ 
    position: static;
}

.dropdown-menu
{
    margin-top: 0px !important;
    padding-top: 0px;
    padding-bottom: 0px;

    width: 100%;
    
    background-color: transparent;
    border: none;
}

.dropdown-menu-arrow-container
{
    display: flex;
    justify-content: right;

    width: 100%;
    height: 10px;
}

.dropdown-menu-arrow
{
    margin-right: 306px;

    width: 0;
    height: 0;
    border-bottom: 10px solid #252525;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

/* XS to MD */
@media (max-width: 991.98px)
{ 
    .dropdown-menu-arrow
    {
        display: none;
    }
}

.dropdown-menu-bubble
{
    padding: 30px;

    width: 100%;

    color: #ffffff;
    background-color: #252525;

    border: none;
    border-radius: 24px;

    box-shadow: 0px 24px 48px #00000052;
}

/* XS to MD */
@media (max-width: 991.98px)
{ 
    .dropdown-menu-bubble
    {
        margin-bottom: 15px;

        box-shadow: none;
    }
}

.dropdown-menu-link
{
    display: inline-block;

    margin-right: 24px;
    
    color: #ffffff;
}

.dropdown-menu-link:hover
{
    cursor: pointer;

    color: #ffffff;
    text-decoration: underline;
}
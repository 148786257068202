.masonry-container
{
    margin-top: 15px;
}

.masonry
{
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;

    padding-top: 15px;
    
    width: auto;
}

/* XS to MD */
@media (max-width: 991.98px)
{ 
    .masonry
    {
        margin-left: -30px; /* gutter size offset */
    }
}
/* LG */
@media (min-width: 992px) and (max-width: 1199.98px)
{
    .masonry
    {
        margin-left: -40px; /* gutter size offset */
    }
}
/* XL */
@media (min-width: 1200px)
{
    .masonry
    {
        margin-left: -60px; /* gutter size offset */
    }
}

.masonry-column
{
    background-clip: padding-box;
}

/* XS to MD */
@media (max-width: 991.98px)
{ 
    .masonry-column
    {
        padding-left: 30px; /* gutter size */
    }
}
/* LG */
@media (min-width: 992px) and (max-width: 1199.98px)
{
    .masonry-column
    {
        padding-left: 40px; /* gutter size */
    }
}
/* XL */
@media (min-width: 1200px)
{
    .masonry-column
    {
        padding-left: 60px; /* gutter size */
    }
}

.masonry-column > div
{
    padding: 30px;

    padding-left: 30px;
    padding-right: 30px;

    margin-bottom: 60px;

    background-color: #ffffff;
    
    border-style: solid;
    border-radius: 24px;
    border-width: 1px;
    border-color: #eeeeee;

    box-shadow: 0px 10px 32px #00000017;
}

.masonry-column-single
{
    padding: 30px;

    padding-left: 30px;
    padding-right: 30px;

    margin-top: 30px;
    margin-bottom: 60px;

    background-color: #ffffff;

    border-style: solid;
    border-radius: 24px;
    border-width: 1px;
    border-color: #eeeeee;

    box-shadow: 0px 10px 32px #00000017;
}

.masonry-column-single-left
{
    padding: 30px;

    padding-left: 30px;
    padding-right: 30px;

    margin-right: 15px;
    margin-bottom: 60px;

    background-color: #ffffff;

    border-style: solid;
    border-radius: 24px;
    border-width: 1px;
    border-color: #eeeeee;

    box-shadow: 0px 10px 32px #00000017;
}

.masonry-column-single-right
{
    padding: 30px;

    padding-left: 30px;
    padding-right: 30px;

    margin-left: 15px;
    margin-bottom: 60px;

    background-color: #ffffff;

    border-style: solid;
    border-radius: 24px;
    border-width: 1px;
    border-color: #eeeeee;

    box-shadow: 0px 10px 32px #00000017;
}
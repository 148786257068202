.debug-responsive-container
{
    position: fixed;
    display: inline-block;

    bottom: 0px;
    left: 0px;

    z-index: 9999;
}

.debug-responsive-block
{
    display: inline-block;

    width: 16px;
    height: 16px;

    background-color: blue;
}

.debug-responsive-none
{
    display: inline-block;

    width: 16px;
    height: 16px;
    
    border-style: solid;
    border-width: 1px;
    border-color: blue;
}

.debug-responsive-text
{
    position: absolute;

    top: -3px;
    margin-left: 4px;

    color: blue;
}

.debug-responsive-text-link
{
    position: absolute;

    top: -3px;
    margin-left: 35px;

    color: blue;
}

.debug-responsive-text-link:hover
{
    cursor: pointer;

    text-decoration: underline;
}
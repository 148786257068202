.blog-title-mobile
{
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;

    color: #212529;
}

.blog-title-desktop
{
    margin-bottom: 12px;

    font-size: 32px;
    line-height: 38px;
    font-weight: 700;

    color: #212529;
}

.blog-date
{
    margin-bottom: 32px;

    color: rgb(100, 100, 100);
}

.blog-text
{
    margin-bottom: 48px;
}

.blog-text h2
{
    margin-bottom: 24px;
    padding-top: 8px;

    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
}

.blog-text h3
{
    margin-bottom: 24px;
    padding-top: 8px;

    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
}

.blog-profile-container
{
    padding: 30px;
    margin-top: 32px;
    margin-bottom: 48px;

    background: rgb(188,235,96);
    background: linear-gradient(90deg, rgba(188,235,96,1) 0%, rgba(114,190,32,1) 100%);

    border-radius: 12px;

    box-shadow: 0px 10px 32px #00000017;
}

.blog-profile-inner
{
    display: flex;
    align-items: center;
    justify-content: left;
}

.banner-profile-intro
{
    display: block;
    
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    
    color: #ffffff;
}

.blog-profile-name
{
    display: block;

    padding-top: 16px;

    font-size: 24px;
    font-weight: 500;
    line-height: 26px;

    color: #ffffff;
}

.blog-profile-quote
{
    display: block;

    padding-top: 4px;

    font-size: 16px;
    line-height: 19px;

    color: #ffffff;
}

.blog-profile-buttons
{
    display: block;

    padding-top: 16px;
}

.blog-profile-linkedin
{
    display: inline-block;

    margin-top: 0px;
    margin-bottom: 12px;
    margin-right: 12px;

    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 8px;
    padding-right: 8px;
    
    width: 140px;
    height: 32px;

    text-align: center;
    font-size: 12px;
    font-weight: 600;
    line-height: 24px;

    color: #ffffff;
    background-color: #0b66c2;

    border-radius: 6px;
}

.blog-profile-linkedin:hover
{
    cursor: pointer;
    
    text-decoration: none;
    color: #ffffff;
    background-color: #1f7ad5;
}
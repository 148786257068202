.footer-section
{
    padding-top: 48px;
    padding-bottom: 48px;

    font-size: 16px;
    line-height: 22px;
    color: #939393;

    background-color: #252525;
}

.footer-column-spacing
{
    height: 22px;
}

.footer-row-spacing-48
{
    margin-top: 48px;
}

.footer-row-spacing-24
{
    margin-top: 24px;
}

.footer-line
{
    margin-top: 8px;
    margin-bottom: 8px;

    background-color: #939393;
    color: #939393;
}

.footer-spacing
{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-text
{
    font-size: 16px;
    line-height: 22px;

    color: #939393;
}

.footer-text-seperator
{
    font-size: 16px;
    line-height: 22px;

    margin-right: 24px;

    color: #939393;
}

.footer-link
{
    display: inline-block;

    margin-right: 24px;
    
    color: #ffffff;
}

.footer-link:hover
{
    cursor: pointer;

    color: #ffffff;
    text-decoration: underline;
}

.footer-link-alt
{
    display: inline-block;
    
    color: #298ce8;
}

.footer-link-alt:hover
{
    cursor: pointer;

    color: #0056b3;
    text-decoration: underline;
}

.footer-link-external
{
    display: inline-block;

    margin-left: 24px;
    
    color: #ffffff;
}

.footer-link-external:hover
{
    cursor: pointer;

    color: #ffffff;
    text-decoration: underline;
}

.footer-icon
{
    padding-left: 2px;
    font-size: 13px;
}
.table-section
{
    width: 100%;

    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.table-element
{
    padding: 30px;

    padding-left: 30px;
    padding-right: 30px;

    margin-top: 15px;
    margin-bottom: 60px;

    background-color: #ffffff;

    border-style: solid;
    border-radius: 24px;
    border-width: 1px;
    border-color: #eeeeee;

    box-shadow: 0px 10px 32px #eeeeee;
}

.table-header-row
{
    font-weight: 500;

    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.table-header-column
{
    padding-left: 8px;
    padding-right: 8px;

    height: 48px;

    color: #000000;
}

.table-header-column:hover
{
    cursor: pointer;

    background-color: #eeeeee;
}

.table-header-column-text
{
    color: #000000;
}

.table-header-column-text-nosort
{
    color: #c8c8c8;
}

.table-header-column-sort-both
{
    display: inline-block;

    margin-left: 4px;

    width: 12px;
    height: 12px;

    background-image: url("/public/image/icon_sort_both.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.table-header-column-sort-none
{
    display: inline-block;

    margin-left: 4px;

    width: 12px;
    height: 12px;

    background-image: url("/public/image/icon_sort_none.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.table-header-column-sort-up
{
    display: inline-block;

    margin-left: 4px;

    width: 12px;
    height: 12px;

    background-image: url("/public/image/icon_sort_up.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.table-header-column-sort-down
{
    display: inline-block;

    margin-left: 4px;

    width: 12px;
    height: 12px;

    background-image: url("/public/image/icon_sort_down.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.table-row
{
    padding-left: 8px;

    height: 48px;

    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: #eeeeee;
}

.table-row:hover
{
    cursor: pointer;

    background-color: #eeeeee;
}

.table-row-new
{
    padding: 0.25em 0.5em;
    margin: 0;
    border-radius: 1.0em;

    font-size: 12px;
    font-weight: 500;

    color: #878787;
    background-color: #ffffff;

    border: 1px solid #878787;
}

.table-row-discount
{
    padding: 0.25em 0.5em;
    margin: 0;
    border-radius: 1.0em;

    font-size: 12px;
    font-weight: 500;

    color: #ffffff;
    background-color: #a6dc4f;

    border: 1px solid #a6dc4f;
}

.table-row-premium
{
    padding: 0.25em 0.5em;
    margin: 0;
    border-radius: 1.0em;

    font-size: 12px;
    font-weight: 500;

    color: #ffffff;
    background-color: #514fdc;

    border: 1px solid #514fdc;
}

.table-row-premium-star
{
    color: #ffd21d; 
}

.table-row-link
{
    width: 100%;
    padding-right: 8px;

    color: #298ce8;

    text-align: right;

    transition: all .25s ease-out;
}
.discount-section
{
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.discount-subtitle
{
    width: 100%;

    margin-top: 0px;
    margin-bottom: 0px;
    
    text-align: center;
    font-size: 26px;
    line-height: 28px;

    color: #ffffff
}

.discount-title
{
    margin: 0;
    margin-bottom: 0px;

    width: 100%;

    color: #ffffff;

    text-align: center;
    font-size: 64px;
    font-weight: 500;
    line-height: 76px;
}
.panel-section-continuous
{
    padding-top: 48px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.panel-section-single
{
    padding-top: 78px;
    padding-bottom: 78px;
}

.panel-section
{
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
}

.panel-title
{
    display: inline-block;
    
    margin-left: 8px;
    margin-bottom: 30px;

    font-size: 32px;
    line-height: 38px;

    color: #000000;
}

.panel-title-link
{
    display: inline-block;

    margin-left: 8px;
    margin-bottom: 30px;

    font-size: 32px;
    line-height: 38px;
    
    color: #000000;
}

.panel-title-link:hover
{
    cursor: pointer;

    text-decoration: underline;

    transition: all .25s ease-out;
}

.panel-title-blog-link
{
    display: inline-block;

    margin-left: 8px;
    margin-bottom: 8px;

    font-size: 24px;
    line-height: 29px;
    
    color: #000000;
}

.panel-title-blog-link:hover
{
    cursor: pointer;

    text-decoration: underline;

    transition: all .25s ease-out;
}

.panel-title-h2-small
{
    font-size: 24px;
    line-height: 29px;

    color: #212529;
}

.panel-title-h2-small-accent
{
    font-size: 24px;
    line-height: 29px;

    color: #000000;

    font-weight: 700;
}

.panel-title-h2
{
    font-size: 32px;
    line-height: 38px;

    color: #212529;
}

.panel-title-h2-accent
{
    font-size: 32px;
    line-height: 38px;

    color: #000000;

    font-weight: 700;
}

.panel-bottom
{
    margin-top: 36px;

    margin-left: 8px;
    margin-right: 8px;
}

.panel-domain-container-first
{
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-left: 8px;
    padding-right: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    min-height: 48px;

    font-size: 16px;

    border-top-style: solid;
    border-top-color: #ffffff;
    border-top-width: 1px;
}

.panel-domain-container-first:hover
{
    cursor: pointer;

    text-decoration: none !important;

    background-color: #eeeeee;
}

.panel-domain-container
{
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-left: 8px;
    padding-right: 8px;
    padding-top: 12px;
    padding-bottom: 12px;
    min-height: 48px;

    font-size: 16px;

    border-top-style: solid;
    border-top-color: #eeeeee;
    border-top-width: 1px;
}

.panel-domain-container:hover
{
    cursor: pointer;

    text-decoration: none !important;

    background-color: #eeeeee;
}

.panel-domain-buttons
{
    display: inline-block;
}

.panel-domain-name
{
    margin-right: 6px;

    color: #000000;
}

.panel-domain-link
{
    margin-left: 6px;

    color: #298ce8;
}

.panel-footer-link
{
    text-decoration: none;
    color: #298ce8;
}

.panel-footer-link:hover
{
    cursor: pointer;

    text-decoration: underline;

    transition: all .25s ease-out;
}

.panel-domain-new
{
    display: inline-block;

    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-left: 0px;
    margin-right: 7px;
    border-radius: 1.0em;

    font-size: 12px;
    font-weight: 500;

    color: #878787;
    background-color: #ffffff;

    border: 1px solid #878787;
}

.panel-domain-discount
{
    display: inline-block;

    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-left: 0px;
    margin-right: 7px;
    border-radius: 1.0em;

    font-size: 12px;
    font-weight: 500;

    color: #ffffff;
    background-color: #a6dc4f;

    border: 1px solid #a6dc4f;
}

.panel-domain-premium
{
    display: inline-block;

    padding: 0.25em 0.5em;
    margin: 0.125em;
    margin-left: 0px;
    margin-right: 7px;
    border-radius: 1.0em;

    font-size: 12px;
    font-weight: 500;

    color: #ffffff;
    background-color: #514fdc;

    border: 1px solid #514fdc;
}

.panel-domain-premium-star
{
    color: #ffd21d; 
}